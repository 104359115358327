import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Running a Thrift service";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const Warning = makeShortcode("Warning");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "running-a-thrift-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#running-a-thrift-service",
        "aria-label": "running a thrift service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Running a Thrift service`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#thttpservice"
        }}>{`THttpService`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#serialization-formats"
        }}>{`Serialization formats`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#service-multiplexing"
        }}>{`Service multiplexing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#see-also"
        }}>{`See also`}</a></li>
    </ul>
    <Tip mdxType="Tip">
      <p>{`Visit `}<a parentName="p" {...{
          "href": "https://github.com/line/armeria-examples"
        }}>{`armeria-examples`}</a>{` to find a fully working example.`}</p>
    </Tip>
    <p>{`Let's assume we have the following Thrift IDL:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-protobuf"
      }}>{`namespace java com.example.thrift.hello

service HelloService {
    string hello(1:string name)
}
`}</code></pre>
    <p>{`The Apache Thrift compiler will produce some Java code under the `}<inlineCode parentName="p">{`com.example.thrift.hello`}</inlineCode>{` package.
The most noteworthy one is `}<inlineCode parentName="p">{`HelloService.java`}</inlineCode>{` which defines the service interfaces we will implement:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import org.apache.thrift.TException;
import org.apache.thrift.async.AsyncMethodCallback;

public class HelloService {
    public interface Iface {
        public String hello(String name) throws TException;
    }

    public interface AsyncIface {
        public void hello(String name, AsyncMethodCallback<String> resultHandler) throws TException;
    }
    ...
}
`}</code></pre>
    <p>{`If you are interested in going fully asynchronous, it is recommended to implement the `}<inlineCode parentName="p">{`AsyncIface`}</inlineCode>{` interface,
although it is easier to implement the synchronous `}<inlineCode parentName="p">{`Iface`}</inlineCode>{` interface:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import org.apache.thrift.TException;
import org.apache.thrift.async.AsyncMethodCallback;

public class MyHelloService implements HelloService.AsyncIface {
    @Override
    public void hello(String name, AsyncMethodCallback<String> resultHandler) {
        resultHandler.onComplete("Hello, " + name + '!');
    }
}

// or synchronously:
public class MySynchronousHelloService implements HelloService.Iface {
    @Override
    public String hello(String name) throws TException {
        return "Hello, " + name + '!';
    }
}
`}</code></pre>
    <h2 {...{
      "id": "thttpservice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thttpservice",
        "aria-label": "thttpservice permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h2">{`THttpService`}</inlineCode></h2>
    <p>{`Once you've finished the implementation of the interface, you need to wrap it with a `}<a parentName="p" {...{
        "href": "type://THttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/thrift/THttpService.html"
      }}>{`type://THttpService`}</a>{`
and add it to the `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ServerBuilder sb = Server.builder();
...
sb.service("/hello", THttpService.of(new MyHelloService()));
...
Server server = sb.build();
server.start();
`}</code></pre>
    <h2 {...{
      "id": "serialization-formats",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#serialization-formats",
        "aria-label": "serialization formats permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Serialization formats`}</h2>
    <p><a parentName="p" {...{
        "href": "type://THttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/thrift/THttpService.html"
      }}>{`type://THttpService`}</a>{` supports four Thrift serialization formats: TBINARY, TCOMPACT, TJSON and TTEXT.
It chooses the serialization format based on the value of the `}<inlineCode parentName="p">{`content-type`}</inlineCode>{` HTTP header.`}</p>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Header value`}</p></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Serialization format`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Unspecified or
`}<inlineCode parentName="p">{`application/x-thrift`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Use the default serialization format
(TBINARY unless specified)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`application/x-thrift; protocol=TBINARY`}</inlineCode>{` or `}<br />{`
`}<inlineCode parentName="p">{`vnd.apache.thrift.binary`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`TBINARY`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`application/x-thrift; protocol=TCOMPACT`}</inlineCode>{` or `}<br />{`
`}<inlineCode parentName="p">{`vnd.apache.thrift.compact`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`TCOMPACT`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`application/x-thrift; protocol=TJSON`}</inlineCode>{` or `}<br />{`
`}<inlineCode parentName="p">{`vnd.apache.thrift.json`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`TJSON`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`application/x-thrift; protocol=TTEXT`}</inlineCode>{` or `}<br />{`
`}<inlineCode parentName="p">{`vnd.apache.thrift.text`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`TTEXT`}</p></td></tr></tbody></table>
    <p>{`To change the default serialization format from TBINARY to something else, specify it when creating a
`}<a parentName="p" {...{
        "href": "type://THttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/thrift/THttpService.html"
      }}>{`type://THttpService`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.thrift.ThriftSerializationFormats;

ServerBuilder sb = Server.builder();
// Use TCOMPACT as the default serialization format.
sb.service("/hello", THttpService.of(new MyHelloService(),
                                     ThriftSerializationFormats.COMPACT));
`}</code></pre>
    <p>{`You can also choose the list of allowed serialization formats:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`ServerBuilder sb = Server.builder();
// Use TBINARY as the default serialization format.
// Allow TBINARY and TCOMPACT only.
sb.service("/hello", THttpService.of(new MyHelloService(),
                                     ThriftSerializationFormats.BINARY,
                                     ThriftSerializationFormats.COMPACT));
`}</code></pre>
    <Warning mdxType="Warning">
TTEXT is not designed for efficiency and is recommended to be only used for debugging.
It's best to serve from a separate path only accessible internally.
    </Warning>
    <h2 {...{
      "id": "service-multiplexing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#service-multiplexing",
        "aria-label": "service multiplexing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Service multiplexing`}</h2>
    <p><a parentName="p" {...{
        "href": "type://THttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/thrift/THttpService.html"
      }}>{`type://THttpService`}</a>{` supports service multiplexing fully compatible with Apache Thrift `}<a parentName="p" {...{
        "href": "https://github.com/apache/thrift/blob/400b346db2510fffa06c0ced11105e3618ce5367/lib/java/src/org/apache/thrift/TMultiplexedProcessor.java#L28"
      }}>{`TMultiplexedProcessor`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`Map<String, Object> impls = new HashMap<>();
impls.put("foo", new MyFooService());
impls.put("bar", new MyBarService());
// Use MyHelloService for non-multiplexed requests.
impls.put("", new MyHelloService());

sb.service("/thrift", THttpService.of(impls));
`}</code></pre>
    <h2 {...{
      "id": "see-also",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#see-also",
        "aria-label": "see also permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`See also`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/docs/client-thrift"
        }}>{`Calling a Thrift service`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      